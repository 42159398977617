<template>
  <v-container fluid class="pt-0" :class="{'pt-14': is_modal}">
    <MainModal
        :main="{ component: 'Gallery', title: 'Галерея' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_gallery)"
        :modal="show_gallery_dialog"
        @updateItemModal="galleryUpdateItemModal"
    />
    <v-row justify="center">
      <v-col cols="12">
        <v-card elevation="1" class="fill-height" min-height="280" >
          <v-card-title class="pa-0 mb-2">
            <v-toolbar elevation="0" class="pa-2">
              <v-toolbar-title>
                Фотогалерея
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="openGalleryCreateDialog"
                         v-bind="attrs"
                         v-on="on"
                         class="grey lighten-4 mr-1">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Створити новий запис у фотогалереї</span>
              </v-tooltip>
            </v-toolbar>
          </v-card-title>
          <v-data-table
              v-model="selected"
              :headers="tableHeader"
              :items="items"
              :single-select="true"
              :show-select="selectable"
              no-data-text="Дані відсутні. Жодного запису не виявлено"
              :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1],

                }"
              class="custom-table custom-table-1"
              @click:row="onGalleryItemClick"
              @item-selected="selectRow"
          >
            <template v-slot:item.icon="{ item }">
              <v-icon size="26" color="grey darken-1" class="mr-2">
                {{ getFileIcon(item.file_ext) }}
              </v-icon>
            </template>

            <template v-slot:item.create_date="{ item }">
              <span>
                {{ item.create_date | formatDate }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {GET_SITE_GALLERY} from "@/store/actions/personalSite/site";
import {ext_obj} from "@/utils/icons";

export default {
  props: ['selectable', 'model', 'is_modal'],
  name: "Gallery",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      items: 'getGallery'
    })
  },
  data() {
    return {
      selected: [],
      tableHeader: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Тип файлу', value: 'file_ext', width: 200 },
        { text: 'Назва файлу', value: 'file_name_origin', width: 200 },
        { text: 'Коментар', value: 'comment' },
        { text: 'Створено', value: 'create_date', width: 100},
      ],
      selected_gallery: {},
      show_gallery_dialog: false
    }
  },
  methods: {
    ...mapActions({
      fetch: GET_SITE_GALLERY
    }),
    getFileIcon(ext) {
      if (!ext) {
        return 'mdi-file-image-outline'
      }
      const file_ext = (ext_obj.find(item_ext => item_ext.ext === ext)) || {ext: 'txt', mime: ''}
      return file_ext.icon || 'mdi-file-image-outline'
    },
    selectRow(payload) {
      this.$emit('selectedItemRow', payload.value ? payload.item : undefined)
    },
    galleryUpdateItemModal() {
      this.show_gallery_dialog = false
      this.selected_gallery = {}
    },
    openGalleryCreateDialog() {
      this.selected_gallery = {}
      this.show_gallery_dialog = true
    },
    onGalleryItemClick(payload) {
      this.selected_gallery = JSON.parse(JSON.stringify(payload))
      this.show_gallery_dialog = true
    },
  },
  watch: {
    model(payload) {
      if (payload) {
        this.selected = []
        this.fetch()
      }
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped>

</style>